<template>
  <v-card elevation="0" max-width="1010" rounded="xl" class="mx-auto pa-sm-8 pa-4 registration width100">
    <v-img height="105" contain class="mx-auto mb-12" src="@/assets/icon/logo.svg" alt="logo"></v-img>
    <div class="f30 arabic-black text-center mb-2 black--text">{{ $t('singUp.title') }}</div>
    <div class="gray--text text-center mb-4">{{ $t('singUp.description') }}</div>
    <div style="max-width: 800px" class="mx-auto">
      <v-form @submit.prevent="singUp" ref="form" lazy-validation :key="data.birthdayOld">
        <div class="d-flex flex-sm-nowrap flex-wrap justify-center registration_fields">
          <div>
            <div class="black--text">{{ $t('input.idNumber') }}</div>
            <v-text-field
              v-model="data.id_number"
              :error-messages="idErrors"
              :placeholder="$t('input.id')"
              outlined
              required
              color="secondary"
              maxlength="10"
            ></v-text-field>
          </div>
          <div>
            <div class="black--text">{{ $t('input.birthday') }}</div>
            <v-menu v-model="modalDate" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="data.birthdayOld"
                  :error-messages="dateErrors"
                  :placeholder="$t('input.birthdayPla')"
                  outlined
                  required
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-slot:append>
                    <img class="link" width="24" height="24" @click="show = !show" :src="getIcon('calendar')" />
                  </template>
                </v-text-field>
              </template>
              <VHijriDatePicker
                v-model="data.birthdayOld"
                no-title
                color="primary"
                :active-picker.sync="activePicker"
                :locale="$root.$i18n.locale === 'en' ? 'en' : 'ar'"
                :max="minBirth"
                @input="modalDate = false"
              />
            </v-menu>
          </div>
        </div>
        <div class="d-flex flex-sm-nowrap flex-wrap justify-center registration_fields">
          <div>
            <div class="black--text">{{ $t('input.phone') }}</div>
            <v-text-field
              v-model="data.phone_number"
              :placeholder="$t('input.phone')"
              :error-messages="phoneErrors"
              outlined
              required
              color="secondary"
              maxlength="15"
            >
              <template v-slot:prepend-inner>
                <div class="d-flex align-center link" @click="modal = true" style="min-width: 96px; margin-top: -1px">
                  <img
                    v-if="!$vuetify.rtl"
                    width="32"
                    height="24"
                    :src="flagImg(data.countryCode)"
                    :srcset="flagImgSet(data.countryCode)"
                  />
                  <!-- <v-card flat tiel width="15" height="20">
                    <v-icon style="font-size: 16px">mdi-chevron-down</v-icon>
                  </v-card> -->
                  <div class="ms-1 black--text d-flex align-center">
                    (<span v-if="!$vuetify.rtl">+</span>
                    {{ flagList.find((item) => item.ct == data.countryCode).cl }}
                    <span v-if="$vuetify.rtl">+</span>)
                  </div>
                  <img
                    v-if="$vuetify.rtl"
                    class="rounded-circle ms-2 me-1"
                    width="24"
                    height="24"
                    :src="flagImg(data.countryCode)"
                    :srcset="flagImgSet(data.countryCode)"
                  />
                </div>
              </template>
            </v-text-field>
          </div>
          <div>
            <div class="black--text">{{ $t('input.email') }}</div>
            <v-text-field
              v-model="data.email"
              :placeholder="$t('input.email')"
              :error-messages="emailErrors"
              outlined
              required
              color="secondary"
            >
            </v-text-field>
          </div>
        </div>
        <div class="d-flex flex-sm-nowrap flex-wrap justify-center registration_fields">
          <div>
            <div class="black--text">{{ $t('input.password') }}</div>
            <v-text-field
              v-model.trim="data.password"
              :placeholder="$t('input.password')"
              :error-messages="passwordErrors"
              :type="show ? 'text' : 'password'"
              outlined
              required
              color="secondary"
            >
              <template v-slot:append>
                <img class="link" width="24" height="24" @click="show = !show" :src="getIcon(show ? 'passShow' : 'passHide')" />
              </template>
            </v-text-field>
          </div>
          <div>
            <div class="black--text">{{ $t('input.confirmPassword') }}</div>
            <v-text-field
              v-model.trim="data.confirmPassword"
              :placeholder="$t('input.confirmPassword')"
              :error-messages="passwordConfirmErrors"
              :type="showCom ? 'text' : 'password'"
              outlined
              required
              color="secondary"
            >
              <template v-slot:append>
                <img class="link" width="24" height="24" @click="showCom = !showCom" :src="getIcon(showCom ? 'passShow' : 'passHide')" />
              </template>
            </v-text-field>
          </div>
        </div>
        <div class="text-center mt-4">
          <v-btn height="64" depressed type="submit" max-width="380" width="100%" large class="primary radius10">
            <span class="text-body-1 font-weight-bold">{{ $t('btn.continue') }}</span>
          </v-btn>
        </div>
        <div class="d-flex align-center justify-center mt-5 mb-10">
          <span class="gray--text text-body-2 pe-2">{{ $t('singUp.accaunt') }}</span>
          <router-link class="secondary--text text-body-2 font-weight-bold btn-underline" to="/sign-in">
            {{ $t('btn.signIn') }}
          </router-link>
        </div>
      </v-form>
    </div>
    <v-dialog max-width="340" v-model="modal">
      <v-card flat class="pa-3" height="380">
        <v-text-field
          @keyup="searchCountry"
          class="mb-2"
          v-model="country"
          hide-details
          outlined
          required
          color="secondary"
          maxlength="15"
        ></v-text-field>
        <v-card tile flat height="300" class="view-without-scroll pa-2">
          <div v-for="item in requestsFlag" :key="item.ct" class="my-3">
            <div
              :class="accessCountry.find((i) => i.code == item.ct) ? 'd-flex ' : 'd-none'"
              class="link"
              @click="(data.countryCode = item.ct), (modal = false)"
            >
              <img max-width="32" :src="flagImg(item.ct)" :srcset="flagImgSet(item.ct)" />
              <div class="clip">
                <span class="ms-2">{{ item[lang] }}</span>
                <span class="ms-2">(<span v-if="!$vuetify.rtl">+</span>{{ item.cl }}<span v-if="$vuetify.rtl">+</span>)</span>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import json from '@/codes.json';
import { required, sameAs } from 'vuelidate/lib/validators';
import { getGregorianDate, subtractYears } from '@/helpers/years';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    data: {
      password: {
        required,
      },
      confirmPassword: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
  data() {
    return {
      activePicker: 'YEAR',
      modalDate: false,
      modal: false,
      flagList: json,
      requestsFlag: json,
      country: '',
      data: {
        countryCode: 'sa',
        birthdayOld: subtractYears(),
      },
      minBirth: subtractYears(),
      show: false,
      showCom: false,
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getPhoneCountry');
  },
  methods: {
    searchCountry() {
      const value = this.country.toString().toLowerCase();
      this.requestsFlag = this.flagList.filter((i) => {
        return Object.keys(i).some((k) => {
          return i[k].toString().toLowerCase().indexOf(value) !== -1;
        });
      });
    },
    getIcon(code) {
      return require(`@/assets/icon/${code}.svg`);
    },
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    flagImgSet(code) {
      return `
        ${require(`@/assets/icon/flags/2x/img_flag_${code}.png`)} 2x,
        ${require(`@/assets/icon/flags/3x/img_flag_${code}.png`)} 3x
      `;
    },
    async singUp() {
      this.error = [];
      this.data.phone_code = this.data.countryCode.toUpperCase();
      this.data.id_type = 'national_id';
      this.data.birthday = getGregorianDate(this.data.birthdayOld);
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$store
          .dispatch('signUp', this.data)
          .then((res) => {
            sessionStorage.setItem('sid', res.data.sid);
            this.$router.push('/sign-up/confirm');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      }
    },
  },
  watch: {
    modal() {
      if (!this.modal) {
        this.requestsFlag = this.flagList;
        this.country = '';
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.langValue;
    },
    accessCountry() {
      return this.$store.getters.phoneCountry;
    },
    idErrors() {
      const errors = [];
      this.error.find((item) => item == 'id_number__required') && errors.push(this.$t('inputError.idRequired'));
      this.error.find((item) => item == 'id_number__invalid') && errors.push(this.$t('inputError.idInvalid'));
      this.error.find((item) => item == 'national_id__exists') && errors.push(this.$t('inputError.idExiss'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t(''));
      return errors;
    },
    dateErrors() {
      const errors = [];
      this.error.find((item) => item == 'birthday__required') && errors.push(this.$t('inputError.birthdayRequired'));
      this.error.find((item) => item == 'birthday__invalid') && errors.push(this.$t('inputError.birthdayInvalid'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t(''));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      this.error.find((item) => item == 'phone_number__required') && errors.push(this.$t('inputError.phoneRequired'));
      this.error.find((item) => item == 'phone_number__invalid') && errors.push(this.$t('inputError.phoneInvalid'));
      this.error.find((item) => item == 'phone_number__exists') && errors.push(this.$t('inputError.phoneExists'));
      this.error.find((item) => item == 'country__not_allowed') && errors.push(this.$t('inputError.phoneAllowed'));
      this.error.find((item) => item == 'action__not_allowed') && errors.push(this.$t('inputError.actionNotAllowed'));
      this.error.find((item) => item == 'bad_credentials') && errors.push('');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push(this.$t('inputError.emailRequired'));
      this.error.find((item) => item == 'email__invalid') && errors.push(this.$t('inputError.emailInvalid'));
      this.error.find((item) => item == 'email__already_used') && errors.push(this.$t('inputError.emailUsed'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t(''));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.data.password.$dirty) {
        return errors;
      }
      !this.$v.data.password.required && errors.push(this.$t('inputError.passwordRequired'));
      this.error.find((item) => item == 'password__required') && errors.push(this.$t('inputError.passwordRequired'));
      this.error.find((item) => item == 'password__invalid') && errors.push(this.$t('inputError.passwordInvalid'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t(''));
      return errors;
    },
    passwordConfirmErrors() {
      const errors = [];
      if (!this.$v.data.confirmPassword.$dirty) {
        return errors;
      }
      !this.$v.data.confirmPassword.sameAsPassword && errors.push(this.$t('inputError.passwordConfirm'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t(''));
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setPhoneCountry');
  },
};
</script>

<style lang="scss">
.registration {
  &_fields {
    gap: 0 40px;
  }
  &_fields > div {
    max-width: 380px;
    width: 100%;
  }
}
</style>
